<template>
  <div class="BGS">
    <div class="title" style="font-size:100px;color:red;">404</div>
    <div class="title" style="font-size:30px;">您访问的页面不存在或已被删除。现为您跳转首页！<span>{{date}}</span></div>

  </div>
</template>

<script>
export default {
  metaInfo(){
     return{
        title: '404页面',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      date: 3,
    };
  },
  methods: {
    time() {
      this.clearTimeSet=setInterval(() => {
        this.date--;
        if (this.date < 0) {
            this.date=0;
          location.href = "/";
        }
      }, 1000);
    },
    clearTime() {
  clearInterval(this.clearTimeSet);
}
  },
  created() {
    this.time();
  },
  beforeDestroy(){
    clearInterval(this.clearTimeSet);
  }
};
</script>

<style scoped>
.BGS {
  height: 100%;
  width: 100%;
  position: absolute;
  /* background:red; */
}
.title {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
</style>